<template>
  <div class="filter-company">
    <r-card v-if="isFilterOpen">
      <div class="level mb-0">
        <div class="level-left">
          <h4 class="filter-company__title">Filter</h4>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-icon
              icon="close"
              @click.native="closeFilter"
              class="click"
              custom-size="mdi-18px"
            />
          </div>
        </div>
      </div>
      <div>
        <GeneralFilterContent
          title="Role Access"
          @handleClick="filterBy($event)"
          :list="roles"
          type="roles"
        />

        <GeneralFilterContent
          title="Packages"
          @handleClick="filterBy($event)"
          :list="packages"
          type="package"
        />

        <GeneralFilterContent
          title="Status"
          @handleClick="filterBy($event)"
          :list="statusList"
          type="status"
        />
      </div>
      <div class="level mt-4">
        <div class="level-left">
          <div class="click" @click="resetState()">Reset</div>
        </div>
        <div class="level-right">
          <b-button
            label="Show Result"
            @click.native="id ? onLoadData(id) : onLoadData()"
          />
        </div>
      </div>
    </r-card>
  </div>
</template>
<script>
import GeneralFilterContent from './GeneralFilterContent.vue'

export default {
  props: {
    isFilterOpen: {
      default: false,
      type: Boolean,
    },
    packages: {
      default: null,
      type: Array,
    },
    statusList: {
      default: null,
      type: Array,
    },
    roles: {
      default: null,
      type: Array,
    },
    id: {
      default: 0,
      type: Number,
    },
  },
  methods: {
    /**
     *  Emit close filter from parent
     */
    closeFilter() {
      this.$emit('closeFilter')
    },

    /**
     *  Emit change filter from parent
     *  @param event - listener for change filter
     */
    filterBy(event) {
      this.$emit('filterBy', event)
    },

    /**
     *  Emit fetch data from parent
     *  @param event - listener for fetch/load data
     */
    onLoadData(event) {
      this.$emit('loadData', event)
    },

    /**
     *  Emit reset filter state from parent
     */
    resetState() {
      this.$emit('reset')
    },
  },
  components: { GeneralFilterContent },
}
</script>
