<template>
  <r-page page-title="Company">
    <template #page-header>
      <h2 class="page-title">Payment</h2>
      <r-menu-tab :level="2" />
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <!-- <b-button>Export Excel</b-button> -->
        </div>
        <div class="level-right">
          <div
            class="filter-active role-access"
            v-if="packageArray.length !== 0"
          >
            Package +{{ packageArray.length }}
            <span>
              <b-icon
                class="click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearFilter('package')"
              />
            </span>
          </div>
          <div
            class="filter-active status mr-4"
            v-if="statusArray.length !== 0"
          >
            Status +{{ statusArray.length }}
            <span>
              <b-icon
                class="click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearFilter('status')"
              />
            </span>
          </div>
          <div class="date-billing">
            <b-datepicker
              v-model="filterBillingDate"
              :show-week-number="showWeekNumber"
              :locale="locale"
              placeholder="Billing Date Issue"
              icon="calendar-month"
              :icon-right="selected ? 'close-circle' : ''"
              icon-right-clickable
              @icon-right-click="clearDate"
              trap-focus
            >
              <b-button
                label="Clear"
                type="is-danger"
                icon-left="close"
                outlined
                @click="filterBillingDate = null"
              />
            </b-datepicker>
          </div>
          <div class="date-actual">
            <b-datepicker
              v-model="filterPayDate"
              :show-week-number="showWeekNumber"
              :locale="locale"
              placeholder="Actual Pay Date"
              icon="calendar-month"
              :icon-right="selected ? 'close-circle' : ''"
              icon-right-clickable
              @icon-right-click="clearDate"
              trap-focus
            >
              <b-button
                label="Clear"
                type="is-danger"
                icon-left="close"
                outlined
                @click="filterPayDate = null"
              />
            </b-datepicker>
          </div>
          <b-button
            icon-left="filter-outline"
            class="mx-2"
            type="is-light"
            @click.native="openFilter()"
          >
            <img
              src="../../../public/images/filter-dot.svg"
              v-if="statusArray.length !== 0 || packageArray.length !== 0"
              class="filter-dot-button"
            />
            Filter
          </b-button>
          <GeneralFilter
            :isFilterOpen="isFilterOpen"
            :packages="packageFilter"
            :statusList="statuses"
            @closeFilter="closeFilter"
            @filterBy="filterBy($event)"
            @reset="resetStateFilter()"
            @loadData="loadFilter()"
          ></GeneralFilter>

          <div class="search-lp">
            <b-input
              type="is-light"
              placeholder="Search"
              icon-right="magnify"
              icon-right-clickable
              @icon-right-click="searchIconClick(search)"
              @keydown.native.enter="searchIconClick(search)"
              v-model="search"
            ></b-input>
          </div>
        </div>
      </div>
    </template>
    <template #page-content>
      <b-table
        :data="getListPaymentData"
        :per-page="perPage"
        :current-page.sync="page"
        :loading="getIsLoading"
        :sticky-header="stickyHeaders"
        :total="total"
        paginated
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        hoverable
        ref:table
        backend-pagination
        backend-sorting
        @sort="onSort"
        @page-change="onPageChange"
        class="table-list-payment"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      >
        <template>
          <b-table-column
            field="id"
            v-slot="props"
            sortable
            label="Order ID"
            width="9%"
          >
            <span>{{ '#' + props.row.id }}</span>
          </b-table-column>
          <b-table-column v-slot="props" label="Package" width="13%">
            <span>{{ props.row.items[0].packages }}</span>
          </b-table-column>
          <b-table-column v-slot="props" label="Owner" width="14%">
            <span>
              {{ props.row.user ? props.row.user.fullName : 'null' }}
            </span>
          </b-table-column>
          <b-table-column v-slot="props" label="Billing Cycle" width="10%">
            <span>
              {{ props.row.billingCycle }}
            </span>
          </b-table-column>
          <b-table-column v-slot="props" label="Company" width="10%">
            <span>
              {{ props.row.companyName }}
            </span>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="date_issue"
            label="Billing Date Issue"
            sortable
            width="13%"
          >
            <span>
              {{
                props.row.billingDate
                  ? formatDate(props.row.billingDate, 'DD MMMM YYYY')
                  : '-'
              }}
            </span>
          </b-table-column>
          <b-table-column v-slot="props" sortable label="Amount" width="9%">
            <span>
              {{ 'Rp' + ' ' + props.row.amount }}
            </span>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="pay_date"
            label="Actual Pay Date"
            sortable
            width="13%"
          >
            <span>
              {{
                props.row.actualPayDate
                  ? formatDate(props.row.actualPayDate, 'DD MMMM YYYY')
                  : '-'
              }}
            </span>
          </b-table-column>
          <b-table-column v-slot="props" label="Status" width="10%">
            <span
              :class="
                props && props.row.status == 'paid'
                  ? 'paidClass'
                  : props.row.status == 'unpaid'
                  ? 'unpaidClass'
                  : props.row.status == 'canceled'
                  ? 'canceledClass'
                  : props.row.status == 'refund'
                  ? 'refundClass'
                  : ''
              "
            >
              {{
                props.row.status == 'paid'
                  ? 'Paid'
                  : props.row.status == 'unpaid'
                  ? 'Unpaid'
                  : props.row.status == 'canceled'
                  ? 'Canceled'
                  : props.row.status == 'refund'
                  ? 'Refund'
                  : ''
              }}
            </span>
          </b-table-column>
        </template>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No data found. Please search and select the company first.</p>
            </div>
          </section>
        </template>
      </b-table>
    </template>
  </r-page>
</template>

<script>
// import ListPaymentFilter from '../../components/FilterContent/ListPaymentFilter.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { formatDate } from '@/services/util'
import GeneralFilter from '../../components/FilterContent/GeneralFilter.vue'

export default {
  components: {
    // ListPaymentFilter,
    GeneralFilter,
  },
  data() {
    return {
      data: '',
      isPaginationSimple: true,
      perPage: 9,
      isListPaymentLoading: false,
      search: null,
      isFilterOpen: false,
      page: 1,
      lastPage: 0,
      total: 0,
      sortField: 'id',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      filterPackage: [],
      filterStatus: [],
      packageFilter: [
        {
          title: 'Small Business',
          value: 'small business',
          isSelected: false,
        },
        {
          title: 'Medium Business',
          value: 'medium business',
          isSelected: false,
        },
        {
          title: 'Company',
          value: 'company',
          isSelected: false,
        },
        {
          title: 'Enterprise',
          value: 'enterprise',
          isSelected: false,
        },
      ],
      statuses: [
        {
          title: 'Paid',
          value: 'paid',
          isSelected: false,
        },
        {
          title: 'Canceled',
          value: 'canceled',
          isSelected: false,
        },
        {
          title: 'Refund',
          value: 'refund',
          isSelected: false,
        },
        {
          title: 'Unpaid',
          value: 'unpaid',
          isSelected: false,
        },
      ],
      isUserManagementLoading: false,
      stickyHeaders: false,
      packageArray: [],
      statusArray: [],
      filterBillingDate: '',
      filterPayDate: '',
      billingDateValue: '',
      payDateValue: '',
    }
  },

  watch: {
    filterBillingDate() {
      if (this.filterBillingDate != 'Invalid date') {
        this.loadFilter()
      }
    },
    filterPayDate() {
      if (this.filterPayDate != 'Invalid date') {
        this.loadFilter()
      }
    },
  },

  async mounted() {
    // await this.resetState()
    await this.loadListPaymentIndex()
  },

  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
      getListPaymentData: 'listPayment/getListPaymentData',
      getIsLoading: 'listPayment/getIsLoading',
    }),
  },

  methods: {
    ...mapActions({
      actionLoadListPaymentData: 'listPayment/fetchListPaymentData',
    }),
    ...mapMutations({
      setListPayment: 'listPayment/setListPaymentData',
    }),

    /**
     *  Function for format date
     * @param {string} date - date value
     * @param {string} format - format type
     */
    formatDate(date, format) {
      return formatDate(date, format)
    },

    /**
     *  Function to sort table
     * @param {string} field - field type
     * @param {string} order - order type
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     *  Function to change pagination
     * @param {number} page
     */
    onPageChange(page) {
      this.page = page
      this.loadListPaymentIndex()
    },

    /**
     *  Function to load filter
     */
    async loadFilter() {
      this.resetState()
      this.setListPayment([])
      this.perPage = 9
      await this.loadListPaymentIndex()
    },

    /**
     *  Function to reset filter state
     *  @param {bool} isFiltering - to determine if fetch use filter or not
     */
    async resetState(isFiltering) {
      this.page = 1
      this.lastPage = 0
      this.setListPayment([])
      await this.loadListPaymentIndex(isFiltering)
    },

    /**
     *  Function to reset filter state
     *  @param {bool} isFiltering - to determine if fetch use filter or not
     */
    async resetFilter(isFiltering) {
      this.page = 1
      this.lastPage = 0
      await this.loadListPaymentIndex(isFiltering)
    },

    /**
     *  Handle to reset all filter state
     */
    async resetStateFilter() {
      this.perPage = 9
      this.statusArray = []
      this.packageArray = []
      this.statuses.forEach((el) => (el.isSelected = false))
      this.packageFilter.forEach((el) => (el.isSelected = false))
      this.resetFilter()
      this.setListPayment([])
      await this.loadListPaymentIndex()
    },

    /**
     *  Function to apply filter via search
     * @param {string} evt
     */
    async searchIconClick(evt) {
      await this.resetState(evt)
      // if (this.search == 0) {
      //   this.perPage = 10
      // }
    },

    /**
     *  Handle for open filter
     */
    openFilter() {
      this.isFilterOpen = true
    },

    /**
     *  Handle for close filter
     */
    closeFilter() {
      this.isFilterOpen = false
    },

    /**
     *  Function to fetch payment list
     * @param {bool} isFiltering - to determine if fetch use filter or not
     */
    async loadListPaymentIndex(isFiltering) {
      try {
        const params = {
          perPage: this.perPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: isFiltering,
          filterBillingDate: this.filterBillingDate
            ? 'filter_billing_date'
            : 'null',
          billingDateValue: formatDate(this.filterBillingDate, 'YYYY-MM-DD')
            ? formatDate(this.filterBillingDate, 'YYYY-MM-DD')
            : null,
          filterPayDate: this.filterPayDate ? 'filter_pay_date' : 'null',
          payDateValue: formatDate(this.filterPayDate, 'YYYY-MM-DD')
            ? formatDate(this.filterPayDate, 'YYYY-MM-DD')
            : null,
          filterPackage: this.packageArray[0] ? this.packageArray[0] : null,
          filterPackage2: this.packageArray[1] ? this.packageArray[1] : null,
          filterPackage3: this.packageArray[2] ? this.packageArray[2] : null,
          filterPackage4: this.packageArray[3] ? this.packageArray[3] : null,
          filterStatus: this.statusArray[0] ? this.statusArray[0] : '',
          filterStatus2: this.statusArray[1] ? this.statusArray[1] : '',
          filterStatus3: this.statusArray[2] ? this.statusArray[2] : '',
          filterStatus4: this.statusArray[3] ? this.statusArray[3] : '',
        }

        if (isFiltering) {
          params.isFiltering = true
          params.page = this.page
        } else {
          params.page = this.page
        }

        const response = await this.actionLoadListPaymentData(params)

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (e) {
        console.log(e)
      }
    },

    /**
     *  HandleClick for change filter
     *  @param {object} event - listener for change filter
     */
    filterBy(event) {
      let index = event.index
      const value =
        event.type === 'status'
          ? this.statusArray.indexOf(`filter_status[]=${event.item.value}`)
          : this.packageArray.indexOf(`filter_package[]=${event.item.value}`)

      if (value > -1) {
        this.addFilter({ value, index, type: event.type })
      } else {
        this.removeFilter({ value: event.item.value, index, type: event.type })
      }
    },

    /**
     *  Add filter to array
     *  @param {object} param
     */
    addFilter(param) {
      const { value, index, type } = param

      if (type === 'status') {
        this.statusArray.splice(value, 1)
        this.statuses[index].isSelected = false
      } else {
        this.packageArray.splice(value, 1)
        this.packageFilter[index].isSelected = false
      }
    },

    /**
     *  Remove filter in array
     *  @param {object} param
     */
    removeFilter(param) {
      const { value, index, type } = param

      if (type === 'status') {
        this.statusArray.push(`filter_status[]=${value}`)
        this.statuses[index].isSelected = true
      } else {
        this.packageArray.push(`filter_package[]=${value}`)
        this.packageFilter[index].isSelected = true
      }
    },

    /**
     *  Handle for clear filter by filter type
     * @param {string} type - filter type
     */
    async clearFilter(type) {
      if (type === 'status') {
        this.statusArray = []
        this.statuses.forEach((el) => (el.isSelected = false))
        this.filterStatus = []
      } else {
        this.packageArray = []
        this.packageFilter.forEach((el) => (el.isSelected = false))
        this.filterPackage = []
      }
      this.setListPayment([])
      this.perPage = 9
      await this.loadListPaymentIndex()
    },
  },
}
</script>
