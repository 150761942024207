<template>
  <div class="columns is-multiline" v-if="list && list.length > 0">
    <div class="column is-12 pb-0 filter-company__subtitle">
      Filter by {{ title }}
      <hr class="filter-hr-class" />
    </div>
    <div
      v-for="(item, index) in list"
      :key="`item-${title}-${index}`"
      class="column pt-0 is-4 filter-company__button-container"
    >
      <b-button
        class="filter-company__button"
        :class="item.isSelected ? 'filter-button-clicked' : ''"
        :type="item.isSelected ? 'is-danger' : 'is-light'"
        @click.native="handleClickChangeFilter({ item, index, type })"
      >
        {{ item.title }}
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: '',
      type: String,
    },
    list: {
      default: null,
      type: Array,
    },
    type: {
      default: '',
      type: String,
    },
  },
  methods: {
    /**
     *  Emit change filter from parent
     *  @param event - listener for change filter
     */
    handleClickChangeFilter(event) {
      this.$emit('handleClick', event)
    },
  },
}
</script>
